import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Content5 from "./components/Content5";

function App() {
  return <Content5 />;
}

export default App;

/** @jsxImportSource @emotion/react */
import { Content5Style } from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const Content5 = () => {
  const Content5Data = [
    {
      id: 1,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/54/logoImg/logo.png",
      title: "복어의습격",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/54/game/index.html?c=9999&preview=T",
    },
    {
      id: 2,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/53/logoImg/logo.png",
      title: "벽타고머니",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/53/game/index.html?c=9999&preview=T",
    },
    {
      id: 3,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/52/logoImg/logo.png",
      title: "푸시푸시캣",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/52/game/index.html?c=9999&preview=T",
    },
    {
      id: 4,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/51/logoImg/logo.png",
      title: "축구의신",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/51/game/index.html?c=9999&preview=T",
    },
    {
      id: 5,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/50/logoImg/logo.png",
      title: "카드뒤집기",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/50/game/index.html?c=9999&preview=T",
    },
    {
      id: 6,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/49/logoImg/logo.png",
      title: "풍선팡팡",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/49/game/index.html?c=9999&preview=T",
    },
    {
      id: 7,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/48/logoImg/logo.png",
      title: "해적다람쥐",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/48/game/index.html?c=9999&preview=T",
    },
    {
      id: 8,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/47/logoImg/logo.png",
      title: "스카이콩콩",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/47/game/index.html?c=9999&preview=T",
    },
    {
      id: 9,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/46/logoImg/logo.png",
      title: "농구의신",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/46/game/index.html?c=9999&preview=T",
    },
    {
      id: 10,
      img: "https://cafe-game.admerce.co.kr/game@genieworks.net/45/logoImg/logo.png",
      title: "뇌섹퀴즈",
      link: "https://cafe-game.admerce.co.kr/game@genieworks.net/45/game/index.html?c=9999&preview=T",
    },
  ];
  return (
    <div className="content_manager5" css={Content5Style}>
      <div className="container2">
        <div className="content_main_text2">
          <h3>지니펀</h3>
          <p>
            365일 재밌는 게임을 제공합니다.
            <br />
            게임을 체험해보고
            <br />
            이벤트에 맞는 게임을 골라
            <br />
            사용해보세요!
          </p>
        </div>
        <div className="list"></div>

        <ul className="content_manager5_list">
          {Content5Data.map((list) => (
            <li
              key={list.id}
              className="content_manager5_list_container"
              onClick={() =>
                window.open(
                  `${list.link}`,
                  `${list.title}`,
                  "toolbar=no, width=400, height=700"
                )
              }
            >
              <div className="content_manager5_list_img">
                <img
                  src={`${list?.img}`}
                  alt={`content5 게임 리스트 이미지 ${list.id}`}
                />
              </div>
              <div className="content_manager5_list_text">
                <h4>{list.title}</h4>
              </div>
              <div
                className="content_manager5_list_button"
                onClick={() =>
                  window.open(
                    `${list.link}`,
                    `${list.title}`,
                    "toolbar=no, width=400, height=700"
                  )
                }
              >
                게임하기
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Content5;
